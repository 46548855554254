import React, { useRef, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { IconEnum, SvgIcon, TooltipDisclaimer } from '@ui-elements';
import { Ambassador } from './store';
import { VideoContent } from './video-content';

type HeroContentProp = {
  selectedAmbassador: Ambassador;
  galleryIndex: number;
  translateWidth: number;
  handleClick: (index: number) => void;
};
export const HeroContent = (props: HeroContentProp) => {
  const { selectedAmbassador, galleryIndex, translateWidth } = props;

  const handleNavigate = (value) => {
    props.handleClick(value);
  };

  return (
    <div className={styles.contentBody}>
      <div className={styles.ambassadorBodyContent}>
        <div
          className={styles.contentCopy}
          dangerouslySetInnerHTML={{
            __html: selectedAmbassador.gallery[galleryIndex].copy || '',
          }}
          style={{
            color: `#${selectedAmbassador.gallery[galleryIndex].fontColor}`,
          }}
        />
        <div className={styles.carousel}>
          <div className={styles.innerWrapper}>
            <div
              className={styles.galleryContainerLeft}
              onClick={() => handleNavigate(galleryIndex - 1)}
            >
              <div className={styles.galleryArrow}>
                <SvgIcon type="chevronLeft" size={2.2} color="#FFFFFF" />
              </div>
            </div>
            <div
              className={styles.innerWrapper}
              style={{
                transform: `translateX(-${galleryIndex * translateWidth}px)`,
              }}
            >
              {selectedAmbassador.gallery.map((item, index) => {
                const source = !!item.videoSource
                  ? item.videoSource
                  : item.videoLink;
                return !!item.videoLink || !!item.videoSource ? (
                  <VideoContent galleryItem={item} key={index} />
                ) : (
                  <img
                    key={`${index} ${item.source}`}
                    className={styles.mainImage}
                    src={item.source}
                    loading="lazy"
                  />
                );
              })}
            </div>

            <div className={styles.mainImageTitleContainer}>
              <span
                className={styles.mainImageTitle}
                style={{
                  color: `#${selectedAmbassador.gallery[galleryIndex].disclaimerFontColor}`,
                }}
              >
                {selectedAmbassador.gallery[galleryIndex].disclaimer}
              </span>
            </div>
            <div
              className={styles.galleryContainerRight}
              onClick={() => handleNavigate(galleryIndex + 1)}
            >
              <div className={styles.galleryArrow}>
                <SvgIcon type="chevronRight" size={2.2} color="#FFFFFF" />
              </div>
            </div>
          </div>
          <div>
            <div className={styles.thumbnailRow}>
              {selectedAmbassador.gallery.map((item, index) => {
                return (
                  <img
                    className={styles.thumbnailImage}
                    key={`${index} ${item.source}`}
                    src={item.thumbnail}
                    loading="lazy"
                    onClick={() => handleNavigate(index)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
