import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  createRef,
} from 'react';
import styles from './index.module.scss';
import { Dropdown, SvgIcon, TooltipDisclaimer } from '@ui-elements';
import { TabList } from './tab-list';
import { HeroContent } from './hero-content';
import { AmbassadorSectionProp } from './store';
import { Option } from '~/common/models';
import { scroller } from 'react-scroll';

export const Ambassadors = React.memo((props: AmbassadorSectionProp) => {
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [selectedAmbassadorIndex, setSelectedAmbassadorIndex] = useState(0);
  const [translationWidth, setTranslationWidth] = useState(760);
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [selectedAmbassador, setSelectedAmbassador] = useState(
    props.ambassadors[selectedAmbassadorIndex]
  );
  const [selectedAmbassadorOption, setSelectedAmbassadorOption] = useState({
    label: 'Ambassadors',
    value: '0',
  });

  useLayoutEffect(() => {
    if (containerRef.current !== null) {
      if (containerRef.current?.clientWidth < 391) {
        setTranslationWidth(390);
        setIsMobile(true);
      } else if (containerRef.current?.clientWidth < 769) {
        setTranslationWidth(768);
        setIsMobile(true);
      } else if (containerRef.current?.clientWidth < 1025) {
        setTranslationWidth(640);
      }
    }
  }, []);

  useEffect(() => {
    setSelectedAmbassador(props.ambassadors[selectedAmbassadorIndex]);
    setGalleryIndex(0);
  }, [selectedAmbassadorIndex]);

  const handleClick = (index: number) => {
    if (index > selectedAmbassador.gallery.length - 1) {
      setGalleryIndex(0);
    } else if (index < 0) {
      setGalleryIndex(selectedAmbassador.gallery.length - 1);
    } else {
      setGalleryIndex(index);
    }
  };

  const handleTabClick = (index: number) => {
    setSelectedAmbassadorIndex(index);
  };

  const handleDropdownSelect = (option: Option | null) => {
    if (!!option) {
      setSelectedAmbassadorIndex(Number(option.value));
      scroller.scrollTo(`tile-${option.value}`, {
        horizontal: true,
        smooth: true,
        offset: 0,
        ignoreCancelEvents: true,
        containerId: 'tile-menu',
      });
    }
  };

  const headerStyle = {
    justifyContent: props.headerAlignent || 'center',
  };

  const options = props.ambassadors.map((x, index) => ({
    label: `${x.name}`,
    value: index.toString(),
  }));

  return (
    <div className={styles.ambassadorWrapper} ref={containerRef}>
      <div className={styles.ambassadorContainer}>
        {isMobile && (
          <div className={styles.ambassadorDropdown}>
            <Dropdown
              onChange={handleDropdownSelect}
              value={selectedAmbassadorOption}
              placeholder="Ambassadors"
              options={options}
            />
          </div>
        )}

        <div className={styles.contentHeader} style={headerStyle}>
          <h1>{props.header}</h1>
          {props.showDisclaimer && (
            <TooltipDisclaimer disclaimer={props.disclaimer} />
          )}
        </div>
        {styles.headerCopy && (
          <div
            className={styles.headerCopy}
            dangerouslySetInnerHTML={{ __html: props.headerCopy }}
          />
        )}
        <div className={styles.contentContainer}>
          <TabList
            ambassadors={props.ambassadors}
            handleTabClick={handleTabClick}
            selectedAmbassadorIndex={selectedAmbassadorIndex}
          />
          <HeroContent
            selectedAmbassador={selectedAmbassador}
            galleryIndex={galleryIndex}
            handleClick={handleClick}
            translateWidth={translationWidth}
          />
        </div>
      </div>
    </div>
  );
});
