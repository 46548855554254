import React, { Ref } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Ambassador } from './store';

type TablistProp = {
  ambassadors: Ambassador[];
  selectedAmbassadorIndex: number;
  handleTabClick: (index: number) => void;
};

export const TabList = (props: TablistProp) => {
  const { ambassadors, selectedAmbassadorIndex } = props;
  return (
    <div id={'tile-menu'} className={styles.ambassadorColumn}>
      {ambassadors.map((ambassador, index) => {
        return (
          <div
            className={classNames({
              [styles.ambassadorTile]: true,
              [styles.active]: selectedAmbassadorIndex === index,
            })}
            onClick={() => props.handleTabClick(index)}
            key={index}
            id={`tile-${index}`}
          >
            <div>
              <img src={ambassador.icon} loading="lazy" />
            </div>
            <div>
              <span className={styles.ambassadorTileName}>
                {ambassador.name}
              </span>
              <span>{ambassador.title}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
