import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import styles from './index.module.scss';
import { IconEnum, SvgIcon } from '@ui-elements';
import { GalleryItem } from './store';

type VideoContentProp = {
  galleryItem: GalleryItem;
};
export const VideoContent = (props: VideoContentProp) => {
  const { galleryItem } = props;
  const [isPlayVideo, setPlayVideo] = useState<boolean>(false);

  const handlePlayVideo = () => {
    setPlayVideo(false);
  };
  const handleEnded = () => {
    setPlayVideo(false);
  };

  const source = !!galleryItem.videoSource
    ? galleryItem.videoSource
    : galleryItem.videoLink;
  return (
    <div className={styles.mainImage}>
      {!!!galleryItem.videoLink && !isPlayVideo && (
        <div className={styles.videoPlayContainer}>
          <div>
            <SvgIcon
              type={IconEnum.play}
              color="#323334"
              fill="#323334"
              size="100%"
              onClick={() => {
                setPlayVideo(true);
              }}
            />
          </div>
        </div>
      )}

      <ReactPlayer
        width="100%"
        height="100%"
        pip={false}
        url={source}
        playing={isPlayVideo}
        onClick={handlePlayVideo}
        onEnded={handleEnded}
        controls={isPlayVideo}
        onPause={handlePlayVideo}
        config={{
          youtube: {
            playerVars: {
              showinfo: 0,
              disablekb: 1,
              controls: 1,
              modestbranding: 1,
            },
          },
        }}
      />
    </div>
  );
};
